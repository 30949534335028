.sliders {
  width: 600px;
}

.swiper-slide {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}

.slide_img-01 img,
.slide_img-02 img,
.slide_img-03 img {
  width: 100%;
}

.slide_img-01 {
  background: #5a4e98;
}

.slide_img-02 {
  background: #d3cef2;
}

.slide_img-03 {
  background: rgba(141, 141, 235, 1) 85%;
}

.slide_img-01,
.slide_img-02,
.slide_img-03 {
  border-radius: 0.8rem;
  margin-bottom: 30px;
}

.slide_item h4 {
  margin-bottom: 10px;
  color: var(--heading-color);
}

.slide_item p {
  color: var(--heading-color);
  line-height: 28px;
}

.sliders h2 {
  font-size: 2.5rem;
}
