/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

:root {
  --primary-color: #1b0f04;
  --heading-color: #e5e8ec;
  --bg-gradient: linear-gradient(
    90deg,
    rgba(103, 101, 240, 1) 27%,
    rgba(141, 141, 235, 1) 85%
  );
  --font-name: 'Questrial', sans-serif;

  /* 
        #9785f2
        #b5a9f3
        #d3cef2
        #d7337a
    */
}

/*========= reset default browser styles ======== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-name);
  color: aliceblue;
  background-image: linear-gradient( 90deg, rgb(91, 64, 20) 27%, rgb(228 174 15) 85% ), url(http://localhost:3000/static/media/bg.8538f5b….jpeg);
  background-repeat: no-repeat;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3 {
}

section {
  padding: 50px 0px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  list-style: none;
}

/* ========= reusable class =========*/
.container {
  padding: 0px;
  padding-left: 15%;
  padding-right:15%;
  margin: auto;
}

.container-img {
  padding: 0px 15px;
  margin: auto;
}

.section_title {
  color: var(--heading-color);
  font-weight: 700;
}
