.hero_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

.hero_content .section_title {
  font-size: 3rem;
  padding-right: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}

.highlights {
  color: var(--primary-color);
}

.hero_content p {
  font-size: 1rem;
  color: var(--heading-color);
  margin-bottom: 30px;
  line-height: 30px;
}

.hero_btns {
  display: flex;
  align-items: center;
  column-gap: 3rem;
}

.watch_btn {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: var(--heading-color);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.watch_btn span {
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  color: var(--primary-color);
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 8px -1px #f98a23;
}

.box-01 {
  width: 400px;
  height: 400px;
  border-radius: 100%;
  border: 25px solid rgb(245, 133, 54);
}

.box-2 {
  width: 350px;
  height: 350px;
  border-radius: 100%;
  border: 25px solid #f5d363;
}

.box-3 {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  border: 25px solid rgb(252, 231, 211);
  width: 100%;
}

.hero_img-wrapper {
  position: relative;
  z-index: 1;
}

.box_img {
  position: absolute;
  top: -12%;
  left: -13%;
  z-index: 10;
  width: 100%;
}

.box_img img {
  width: 121.5%;
}

.heart_rate {
  width: 100px;
  position: absolute;
  top: 55%;
  left: -11%;
  z-index: 10;
  box-shadow: -3px 3px 8px -1px #d3cef2;
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px;
  text-align: center;
}

.heart_rate span {
  width: 30px;
  height: 25px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d3cef2;
  color: #d7337a;
  border-radius: 3px;
  padding: 3px;
}

.heart_rate h5,
h6 {
  font-size: 0.8rem;
  color: var(--heading-color);
  font-weight: 600;
}

.heart_rate h5 {
  margin-bottom: 10px;
}

.heart_rate h6 {
  margin-bottom: 10px;
}

.gym_location {
  position: absolute;
  top: -2%;
  right: -17%;
  z-index: 10;
  box-shadow: 3px -3px 8px -1px #d3cef2;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 1.2rem;
}

.gym_location span {
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: var(--primary-color);
  color: #fff;
  font-size: 1.5rem;
  border-radius: 50%;
}

.gym_location h5 {
  color: var(--heading-color);
  margin-top: 1rem;
  font-size: 0.8rem;
}

.dumble_icon {
  position: absolute;
  top: -2%;
  left: -5%;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 15px 10px;
}

.dumble_icon img {
  width: 200pt
}
