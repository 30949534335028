.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: #241414;
}

.nav_wrapper {
  display: flex;
  align-items: start;
  color: white;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.logo_img {
  /* background: var(--bg-gradient); */
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_img img {
  width: 100%;
}

.logo h2 {
  color: var(--heading-color);
  font-weight: 700 !important;
  font-size: 1.8rem;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav_item {
  color: var(--heading-color);
  font-weight: 600 !important;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1.1rem;
}

.nav_item a {
  color: var(--heading-color);
  font-weight: 600 !important;
  cursor: pointer;
}

.nav_item a:hover {
  color: white;
}

.nav_right {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.register_btn {
  border: none;
  outline: none;
  padding: 10px 20px;
  color: #fff;
  background: var(--primary-color);
  font-size: 1rem;
  border-radius: 5px;
  font-family: var(--font-name);
  font-weight: 500;
  cursor: pointer;
}

.mobile_menu {
  color: var(--heading-color);
  font-size: 1.3rem;
  cursor: pointer;
  display: none;
}

.sticky_header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 90;
  background: #0e0d0d;
}
