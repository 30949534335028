.start_wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 3rem;
  align-items: center;
}

.start_img,
.start_content {
  width: 50%;
}

.start_img img {
  width: 100%;
}

.start_content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.start_content p {
  color: var(--heading-color);
  font-size: 1.1rem;
  line-height: 28px;
  margin-bottom: 50px;
}
